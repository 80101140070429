import React from 'react'
import { Droppable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom'
import BankTransaction from "./bank_transaction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class TransactionStack extends React.Component {

  constructor(props) {
    super(props);
    this.render.bind(this);
  }

  static getItemStyle(isDragging, draggableStyle) {
    return ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",

      // change background colour if dragging
      background: isDragging ? "lightgreen" : "lightgrey",

      // styles we need to apply on draggables
      ...draggableStyle
    })
  };

  render() {
    let stackClass = "";
    if (this.props.count > 2) { stackClass = "stack" }
    if (this.props.count == 2) { stackClass = "short-stack" }

    let hideStackCountPill = (this.props.count || 0) < 2;
    if ("verbose" != this.props.alternative) {
      hideStackCountPill = (this.props.count || 0) < 1;
    }

    return ( <>
      <div className={"transaction-stack" + ((this.props.hideIfNarrowAndEmpty && this.props.count == 0) ? " hide-if-narrow" : "")}>
        <div className="transaction-stack-title-bar">
          <button className={"floating-icon-button circle-arrow-position" + (this.props.count < 2 ? " invisible" : "")} onClick={this.props.cycleCallback}>
            <FontAwesomeIcon icon="arrows-rotate" />
          </button>
          <span className='transaction-stack-title'>{this.props.userFacingName}</span>
        </div>
        <div className="transaction-stack-outline">
          {
            this.props.backupTransaction ? <BankTransaction transaction={this.props.backupTransaction} index={-1} insideInvoice={false} absolute stack={stackClass} /> : null
          }
          <Droppable type="PAYMENT" droppableId={this.props.droppableId}>
            {
              (provided, snapshot) => {
                return <div ref={provided.innerRef} className="transaction-drop-target">
                  {
                    this.props.transaction ? <BankTransaction draggable transaction={this.props.transaction} index={this.props.index} /> : null
                  }
                  {provided.placeholder}
                </div>
              }
            }
          </Droppable>
        </div>
        <StackCountPill language={this.props.language} alternative={this.props.alternative}
          count={this.props.count || 0} hidden={hideStackCountPill} />
      </div> </>
    );
  };
};

class StackCountPill extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let className = 'stack-count-pill floater' + (this.props.hidden ? " invisible" : "");
    if ("verbose" === this.props.alternative) {
      if ("en" === this.props.language) {
        return <div className={className}>
          <span className='count-pill-words'>Still</span>
          <span className='stack-count'>{this.props.count}</span>
          <span className='count-pill-words'>to go</span>
        </div>
      } else {
        return <div className={className}>
          <span className='count-pill-words'>後</span>
          <span className='stack-count'>{this.props.count}</span>
          <span className='count-pill-words'>件</span>
        </div>
      }
    } else {
      if ("en" === this.props.language) {
        return <div className={className}>
          <span className='stack-count'>{this.props.count}</span>
        </div>
      } else {
        return <div className={className}>
          <span className='stack-count'>{this.props.count}</span>
          <span className='count-pill-words'>件</span>
        </div>
      }
    }
  }
}

export default TransactionStack;