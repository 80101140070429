import _ from 'underscore';
import Patio11Utilities from '../lib/patio11_utilities';
const eventBus = require('js-event-bus')();


class Invoice {
  constructor(invoice_id, customer_name, amount) {
    this.customer_name = customer_name;
    this.amount = amount;
    this.invoice_id = invoice_id;
    this.associated_transactions = []
    this.problem_exists = false;
  }

  hasProblem() {
    return this.problem_exists;
  }

  setProblem() {
    this.problem_exists = true;
  }

  clearProblem() {
    this.problem_exists = false;
  }

  reconciledAmount() {
    let reconciledAmount = 0;
    _.each(this.associated_transactions, (trans) => {
      reconciledAmount = reconciledAmount + trans.reconciledAmountFor(this);
    });
    return reconciledAmount;
  }

  amountRequiringReconciliationBefore(transaction) {
    let unreconciledAmount = this.amount;

    let transactionIndex = _.indexOf(this.associated_transactions, transaction);
    let earlierTransactions = this.associated_transactions.slice(0, transactionIndex);

    _.each(earlierTransactions, (trans) => {
      unreconciledAmount = Math.max(0, unreconciledAmount - trans.reconciledAmountFor(this));
    });

    return unreconciledAmount;
  }

  reconciled() {
    return this.reconciledAmount() >= this.amount
  }
}

class BankTransaction {
  constructor(transaction_id, customer_name, amount) {
    this.customer_name = customer_name;
    this.amount = amount;
    this.transaction_id = transaction_id;
    this.associated_invoices = []
  }

  reconciledAmount() {
    let reconciledAmount = 0;
    _.each(this.associated_invoices, (inv) => {
      reconciledAmount = reconciledAmount + this.reconciledAmountFor(inv);
    });
    return reconciledAmount;
  }

  reconciledAmountFor(invoice) {
    let remainderUnspent = this.amount;
    let invoiceRequestedAmount = invoice.amountRequiringReconciliationBefore(this);


    let invoiceIndexInMe = this.associated_invoices.indexOf(invoice);
    let earlierInvoices = this.associated_invoices.slice(0, invoiceIndexInMe);

    _.each(earlierInvoices, (inv) => {
      remainderUnspent = remainderUnspent - inv.amountRequiringReconciliationBefore(this);
      remainderUnspent = Math.max(0, remainderUnspent);
    });

    return Math.min(remainderUnspent, invoiceRequestedAmount);
  }

  reconciled() {
    return this.reconciledAmount() >= this.amount
  }

  reconciledCompletely() {
    return this.reconciledAmount() >= this.amount;
  }

  unreconcile() {
    _.each(this.associated_invoices, (inv) => {
      inv.associated_transactions = _.reject(inv.associated_transactions, t => {
        return t.transaction_id === this.transaction_id;
      });
      inv.clearProblem();
    });
    this.associated_invoices = [];
    eventBus.emit("gameStateMutated");
  }

}

class GameEngine {

  constructor() {
    this.invoices = [];
    this.bank_transactions = []
    this.invoices_hash = {}
    this.bank_transactions_hash = {}
  }

  loadInitialState(initialState) {
    eventBus.emit("gameStateMutated");
  }

  reorderInvoices(fromIndex, toIndex) {
    let newInvoices = Patio11Utilities.reorder(this.invoices, fromIndex, toIndex);
    this.invoices = newInvoices;
    eventBus.emit("gameStateMutated");
    return this.invoices;
  }

  setInvoiceOrder(newInvoices) {
    this.invoices = newInvoices;
    eventBus.emit("gameStateMutated");
  }

  reorderBankTransactions(fromIndex, toIndex) {
    let newBankTransactions = Patio11Utilities.reorder(this.bank_transactions, fromIndex, toIndex);
    this.bank_transactions = newBankTransactions;
    eventBus.emit("gameStateMutated");
    return this.bank_transactions;
  }

  reconcileInvoice(invoice, bankTransaction) {
    let new_transactions = invoice.associated_transactions || [];
    let new_invoices = bankTransaction.associated_invoices || [];

    new_transactions = _.reject(new_transactions, (trans) => {
      return trans.transaction_id === bankTransaction.transaction_id
      });

      new_invoices = _.reject(new_invoices, (inv) => {
        return inv.invoice_id === invoice.invoice_id
        });

        new_transactions.push(bankTransaction);
        new_invoices.push(invoice);

        invoice.associated_transactions = new_transactions;
        bankTransaction.associated_invoices = new_invoices;

        this.invoices_hash[invoice.invoice_id] = invoice;
        this.bank_transactions_hash[bankTransaction.transaction_id] = bankTransaction;

        invoice.clearProblem();

        eventBus.emit("gameStateMutated");

        return invoice;
      }

      lookupInvoice(invoiceOrInvoiceId) {
        let invoice = invoiceOrInvoiceId;
        if ((typeof invoiceOrInvoiceId) === "string") {
          invoice = this.invoices_hash[invoiceOrInvoiceId];
        }
        return invoice;
      }

      lookupBankTransaction(transactionOrTransactionId) {
        let transaction = transactionOrTransactionId;
        if ((typeof transactionOrTransactionId) === "string") {
          transaction = this.bank_transactions_hash[transactionOrTransactionId];
        }
        return transaction;
      }

      isInvoiceReconciled(invoiceOrInvoiceId) {
        let invoice = this.lookupInvoice(invoiceOrInvoiceId);
        return invoice.reconciled();
      }

      isBankTransactionReconciled(transactionOrTransactionId) {
        let transaction = this.lookupBankTransaction(transactionOrTransactionId);
        return transaction.reconciled();
      }

      populateFromFakery() {
        let fd = GameEngine.fakeData();
        this.invoices = _.map(fd.invoices, (d) => {
          return new Invoice(d.invoice_id, d.customer_name, d.amount)
        });
        this.bank_transactions = _.map(fd.bank_transactions, (d) => {
          return new BankTransaction(d.transaction_id, d.customer_name, d.amount)
        });

        this.rehash();
        eventBus.emit("gameStateMutated");
      }

      populateFromSpecifiedFakeData(invoices, bank_transactions) {
        this.populateFromSpecifiedData(invoices, bank_transactions);
        eventBus.emit("gameStateMutated");
      }

      populateFromSpecifiedData(invoices, bank_transactions) {
        this.invoices = _.map(invoices, (d) => {
          return new Invoice(d.invoice_id, d.customer_name, d.amount)
        });
        this.bank_transactions = _.map(bank_transactions, (d) => {
          return new BankTransaction(d.transaction_id, d.customer_name, d.amount)
        });

        this.rehash();
      }


      rehash() {
        let invoices_hash = {}
        _.each(this.invoices, (inv) => {
          invoices_hash[inv.invoice_id] = inv
        });

        let bank_transactions_hash = {}
        _.each(this.bank_transactions, (trans) => {
          bank_transactions_hash[trans.transaction_id] = trans
        });

        this.invoices_hash = invoices_hash;
        this.bank_transactions_hash = bank_transactions_hash;
      }

      problematicInvoicesExist() {
        return _.any(this.invoices, (inv) => {
          return inv.hasProblem();
        });
      }

      serializeInvoicesAndBankTransactions() {
        let invoicesToTransactionsHash = {};
        _.each(this.invoices, (inv) => {
          invoicesToTransactionsHash[inv.invoice_id] = [];
          _.each(inv.associated_transactions, (trans) => {
            invoicesToTransactionsHash[inv.invoice_id].push(trans.transaction_id);
          });
        });
        return invoicesToTransactionsHash;
      }

      static fakeData() {
        return {
          name: 'Patio11',
          invoices: [{
            invoice_id: '#123',
            customer_name: 'Taro Yamada',
            amount: 1234,
            currency: 'JPY'
          },
            {
              invoice_id: '#456',
              customer_name: 'Haruko Tanaka',
              amount: 3523,
              currency: 'JPY'
            },
            {
              invoice_id: '#3523',
              customer_name: 'Hikaru Yamada',
              amount: 1234,
              currency: 'JPY'
            },
            {
              invoice_id: '#4562',
              customer_name: 'Haruko Shimada',
              amount: 3523,
              currency: 'JPY'
            },
            {
              invoice_id: '#8888',
              customer_name: 'John Collison',
              amount: 1234,
              currency: 'JPY'
            },
            {
              invoice_id: '#9999',
              customer_name: 'Patrick McKenzie',
              amount: 3523,
              currency: 'JPY'
            },
          ],
          bank_transactions: [{
            transaction_id: '#123',
            customer_name: 'Taro Yamada',
            amount: 1234,
            currency: 'JPY'
          },
            {
              transaction_id: '#456',
              customer_name: 'Haruko Tanaka',
              amount: 3523,
              currency: 'JPY'
            },
            {
              transaction_id: '#3523',
              customer_name: 'Hikaru Yamada',
              amount: 1234,
              currency: 'JPY'
            },
            {
              transaction_id: '#4562',
              customer_name: 'Haruko Shimada',
              amount: 3523,
              currency: 'JPY'
            },
            {
              transaction_id: '#8888',
              customer_name: 'John Collison',
              amount: 1234,
              currency: 'JPY'
            },
            {
              transaction_id: '#9999',
              customer_name: 'Patrick McKenzie',
              amount: 3523,
              currency: 'JPY'
            },
          ],
        };
      }

    }

    export default GameEngine;