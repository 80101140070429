import React from 'react';
import _ from 'underscore';

import { Droppable, Draggable } from "react-beautiful-dnd";

import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class BankTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.handleUnreconcile = this.handleUnreconcile.bind(this);
  }

  handleUnreconcile() {
    this.props.transaction.unreconcile();
  }

  render() {
    let t = this.props.transaction;
    let transaction_id = t.transaction_id;
    let draggable_id = "draggable-transaction-" + transaction_id;
    let customerName = t.customer_name;
    let amount = t.amount;
    let index = this.props.index;
    let reconciledAmount = t.reconciledAmount();

    let transactionsAttached = t.associated_invoices.length > 0;
    let completelyReconciled = t.reconciledCompletely();

    /*
    Come back to this once the basic css is working
    let className = "bank-transaction-row";
    className = className + ((transactionsAttached) ? " draggable attached" : " draggable unattached");
    className = className + (completelyReconciled ? " completely_reconciled" : "")
    */

    let reconciledAmountClass = "reconciled_amount "
    if (transactionsAttached == 0) {
      reconciledAmountClass = reconciledAmountClass + " hidden";
    } else if (reconciledAmount > amount) {
      // normal to have a bank transfer applied to invoice(s) it doesn't satisfy
      // at least from the bank transfer's point of view
      // reconciledAmountClass = reconciledAmountClass + " alarming";
    } else if (reconciledAmount < amount) {
      reconciledAmountClass = reconciledAmountClass + " concerning";
    } else {
      reconciledAmountClass = reconciledAmountClass + " perfect";
    }

    let actions = transactionsAttached > 0 ? <button className="floating-icon-button paperclip-position" onClick={this.handleUnreconcile}>
      <FontAwesomeIcon icon="paperclip" className="red" />
    </button> : null

    let classes = ['bank-transfer-row', 'floater'];
    if (this.props.absolute) {classes.push('absolute')}
    if (this.props.stack) {classes.push(this.props.stack)}
    let className = classes.join(' ');
    let draggableContents = <div className={className} key={'bt-contents-' + index}>
      {this.props.insideInvoice ? actions : null}
      <span className="details">
        <span className="customer-name">
          {customerName}
        </span>
        <div className="amount">
          &yen; {amount.toLocaleString()}
          {
    this.props.insideInvoice ? null : <span className={reconciledAmountClass}>
              &nbsp; (&yen; {reconciledAmount.toLocaleString()} reconciled)
            </span>
    }
        </div>
      </span>
    </div>

    if (!this.props.draggable) {
      return draggableContents;
    }

    return (<Draggable id={draggable_id} key={draggable_id} draggableId={draggable_id} index={index}>
      {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        key={'bt-draggable-' + index}
        >
          {draggableContents}
        </div>)
      }
    </Draggable>
      );
  }
}
;

export default BankTransaction;