import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Droppable } from "react-beautiful-dnd";

import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InvoiceCard from './invoice_card.jsx'


class InvoiceList extends React.Component {

  constructor(props) {
    super(props);
    this.render.bind(this);
  }

  static getItemStyle(isDragging, draggableStyle) {
    return ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",

      // change background colour if dragging
      background: isDragging ? "lightgreen" : "lightgrey",

      // styles we need to apply on draggables
      ...draggableStyle
    })
  };

  render() {
    let listGroupClassName = "invoice-list ";
    if (this.props.className) {
      listGroupClassName = listGroupClassName + this.props.className;
    }
    return (
      <Droppable type="INVOICE" droppableId="invoiceListDroppable" direction='horizontal'>
        {(provided, snapshot) => (
            <ListGroup ref={provided.innerRef} className={listGroupClassName + (snapshot.isDraggingOver ? " being_dragged_over" : "")}>
              {this.props.invoices.map(function (invoice, invoiceIndex) {
                let draggableStyle = provided.draggableProps ? provided.draggableProps.style : {};
                let key = (invoice.invoiceId ? invoice.invoiceId : invoiceIndex);
                key = "invoice-list-droppable-" + key;
                return (
                  <InvoiceCard invoice={invoice} index={invoiceIndex}
                    key={key}
                    language={this.props.language}
                    draggableStyle={InvoiceList.getItemStyle(snapshot.isDragging, draggableStyle)}
                    hideInvoice={() => this.props.hideInvoice(invoice.invoice_id)}
                  />
                )
              }.bind(this))}
              {provided.placeholder}
            </ListGroup>
        )}

      </Droppable>
    );
  };
};

export default InvoiceList;