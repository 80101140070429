import React from 'react';
import ReactDOM from 'react-dom';

function LanguageSwitcher(props) {
  return <div className='language-switcher'>
    <button
    className={'language-switcher-left' + (props.language == "en" ? " selected" : "")}
    onClick={() => props.setLanguage("en")}
    ><img src="/images/gb-flag.svg" alt="Choose English"/>English</button>
    <button
    onClick={() => props.setLanguage("ja")}
    className={'language-switcher-right' + (props.language == "ja" ? " selected" : "")}
    ><img src="/images/jp-flag.svg" alt="日本語を選択する" />日本語</button>
  </div>
}

export default LanguageSwitcher