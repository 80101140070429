import React from 'react';
import _ from 'underscore';
import ReactMarkdown from 'react-markdown';

import MegaButton from 'components/mega_button';
import Patio11Utilities from '../lib/patio11_utilities';

class ResultModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };

    this.closeThisModal = this.closeThisModal.bind(this);
    this.render = this.render.bind(this);
  }

  closeThisModal() {
    if (this.props.onButtonClick) {
      this.setState({
        show: false,
      });
      this.props.onButtonClick();
    }
  }

  render() {
    Patio11Utilities.maybeLog("Rendering ResultModal", this);

    let highlightErrorExplanation = <></>;
    let beatGameText = "";
    let twitterLink = "https://twitter.com/intent/tweet?text=";
    if (this.props.beatGame) {
      let enLink = twitterLink + "I%20beat%20all%20the%20levels%20of%20KeshikomiSimulator%21%20https%3A%2F%2Fkeshikomisimulator.com";
      let jpLink = twitterLink + "%E6%B6%88%E3%81%97%E8%BE%BC%E3%81%BF%E3%82%B7%E3%83%9F%E3%83%A5%E3%83%AC%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%92%E3%82%84%E3%81%A3%E3%81%A8%E3%82%AF%E3%83%AA%E3%82%A2%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%97%E3%81%9F%EF%BC%81%20https%3A%2F%2Fkeshikomisimulator.com"
      if ("en" === this.props.language) {
        beatGameText = "**Congratulations on beating the game!** [Tweet](" + enLink + ") about it, if you'd like.";
      } else {
        beatGameText = "**おめでとうございます！** ([Twitterにつぶやいて](" + jpLink + ")みますか？)";
      }
    }

    let singleActionButton = <MegaButton mini onClick={this.closeThisModal} text={this.props.buttonText} />;

    if (this.props.beatGame) {
      singleActionButton = <></>;
    }

    if (this.props.highlightErrors) {
      if ("en" === this.props.language) {
        highlightErrorExplanation = <p>
          <strong>Luckily, I noticed which invoices have problems.
          I marked them red for you.&nbsp;</strong>
          <span>Who’s the best senpai?</span>
        </p>
      } else {
        highlightErrorExplanation = <p>
          <strong>間違ってた請求書をわかりやすいように赤くしておきました。</strong>
          <span>いい先輩でしょ？</span>
        </p>
      }
    }

    return (
      <div className="results-modal-backdrop">
        <div className='results-modal'>
          <div className='modal-text-column'>
            <div className='modal-title'>
              {this.props.wonOrLost}
            </div>
            <div className='modal-text'>
              <ReactMarkdown children={this.props.modalText} />
              {highlightErrorExplanation}
              <ReactMarkdown children={beatGameText} />
            </div>
            { singleActionButton }
          </div>
          <div className={'modal-portrait ' + this.props.characterPortrait}/>
        </div>
      </div>
      )
  }
}

export default ResultModal;