import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import _ from 'underscore';

import "../lib/fontawesome"
import Patio11Utilities from "../lib/patio11_utilities";
import GameEngine from "../src/game_engine";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext } from "react-beautiful-dnd";

import InvoiceList from 'components/invoice_list';
import TransactionStack from 'components/transaction_stack';
import ResultModal from 'components/result_modal';
import MegaButton from 'components/mega_button';
import LanguageSwitcher from 'components/language_switcher';

const eventBus = require('js-event-bus')();

class KeshikomiGame extends React.Component {
  constructor(props) {
    super(props);
    window.gameEngine = this.gameEngine = new GameEngine();
    window.keshikomiGame = this;
    this.gameEngine.populateFromFakery();
    let initialState = {
      splash_screen: true,
      gameTrulyOver: false,
      language: languageFromRails,
      invoices: this.gameEngine.invoices,
      bank_transactions: this.gameEngine.bank_transactions,
      max_level_implemented: 12,
      transaction_locations: {
        droppableTransactionInbox: _.range(this.gameEngine.bank_transactions.length),
        droppableTransactionOutbox: [],
        droppableTransactionDesk1: [],
        invoices: [],
      },
      hidden_invoice_ids: [],
    };

    this.state = initialState;

    this.reorderInvoices = this.reorderInvoices.bind(this);
    this.reorderBankTransactions = this.reorderBankTransactions.bind(this);
    this.dispatchEventsForDragEnd = this.dispatchEventsForDragEnd.bind(this);
    this.reconcileInvoice = this.reconcileInvoice.bind(this);

    this.handleMutatedGameState = this.handleMutatedGameState.bind(this);
    this.handleLevelDoneButton = this.handleLevelDoneButton.bind(this);
    this.handleNextLevelButton = this.handleNextLevelButton.bind(this);
    this.handleNewLevelLoaded = this.handleNewLevelLoaded.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.levelMightBeCompleteable = this.levelMightBeCompleteable.bind(this);
    this.unhideProblematicInvoices = this.unhideProblematicInvoices.bind(this);

    this.initializeNewLevel = this.initializeNewLevel.bind(this);
    this.remoteCheckLevelCorrectness = this.remoteCheckLevelCorrectness.bind(this);

    eventBus.on("gameStateMutated", this.handleMutatedGameState);
    eventBus.on("newLevelLoaded", this.handleNewLevelLoaded);
    if (initialLevelJSON) {
      setTimeout(() => {
        this.initializeNewLevel(initialLevelJSON);
      }, 1);
    }

    if ("en" === languageFromRails) {
      console.log("Stripe is hiring engineers, including in Tokyo. Want to make something together? https://stripe.com/jobs/search?l=tokyo");
    } else {
      console.log("ストライプジャパンがエンジニアを募集しています！ 一緒に面白いものを作りましょう。https://stripe.com/jobs/search?l=tokyo");
    }
  }

  resetTransactionLocationState() {
    this.setState((state) => ({
      transaction_locations: {
        droppableTransactionInbox: _.range(state.bank_transactions.length),
        droppableTransactionOutbox: [],
        droppableTransactionDesk1: [],
        invoices: [],
      },
      hidden_invoice_ids: [],
    }));
  }

  // Move all transactions that, according to state.transaction_locations, are still in in invoices, but which are no longer
  // associated with an invoice, onto the desk.
  // Necessary because a transaction can detach itself from invoice without knowing of desktop UI presentation.
  sweepUnreconciledTransactionsToOutbox() {
    Patio11Utilities.maybeLog("sweeping! state:", this.state);
    this.setState(((state) => {
      // list of transactions located in "invoices" that don't have an invoice attached. Should be length 0 or 1.
      let lostTransactionIndices = _.range(state.bank_transactions.length).filter((idx) => {
        return state.bank_transactions[idx].associated_invoices.length == 0 && state.transaction_locations['invoices'].indexOf(idx) != -1;
      })

      let newDroppableTransactionDesk1 = [];
      if (state.transaction_locations.droppableTransactionDesk1) {
        newDroppableTransactionDesk1 = [...lostTransactionIndices, ...state.transaction_locations.droppableTransactionDesk1];
      }

      return {
        transaction_locations: {
          ...state.transaction_locations,
          droppableTransactionDesk1: newDroppableTransactionDesk1,
          invoices: state.transaction_locations.invoices.filter(idx => state.bank_transactions[idx].associated_invoices.length > 0)
        }
      }
    }), (function() {
      // In a callback because depends on up-to-date location information for transactions.
      let enableLevelDoneButton = this.levelMightBeCompleteable();
      this.setState({
        enableLevelDoneButton: enableLevelDoneButton,
      })
    }))
  }

  levelMightBeCompleteable() {
    let completeable = true;
    completeable = !(this.gameEngine.problematicInvoicesExist());

    let transactionsInProcessing = 
    !_.isEmpty(this.state.transaction_locations['droppableTransactionDesk1']);

    transactionsInProcessing = transactionsInProcessing ||
    !_.isEmpty(this.state.transaction_locations['droppableTransactionInbox']);

    completeable = completeable && !transactionsInProcessing;

    return completeable;
  }

  // Forces a refresh of the react component. Dumb for now.
  handleMutatedGameState(restartedLevel) {
    let enableLevelDoneButton = this.levelMightBeCompleteable();

    this.setState({
      invoices: this.gameEngine.invoices,
      bank_transactions: this.gameEngine.bank_transactions,
      enableLevelDoneButton: enableLevelDoneButton,
      lastStateMutate: Date.now(),
    });

    if (restartedLevel) {
      this.resetTransactionLocationState();
    } else {
      this.sweepUnreconciledTransactionsToOutbox();
    }
  }

  handleStartNewLevel() {
    let currentLevel = this.state.level ? this.state.level.ordinal : 0;
    let newLevelOrdinal = Math.min(currentLevel + 1, this.state.max_level_implemented);
    fetch("/game/start_level", {
      method: 'post',
      body: JSON.stringify({
        level_ordinal: newLevelOrdinal,
      }),

      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Patio11Utilities.getCSRFToken(),
      }
    }).then((response) => {
      this.initializeNewLevel(response.json());
    }).then((res) => {
      Patio11Utilities.maybeLog("handleStartNewLevel response", res)
    }).catch((error) => {
      Patio11Utilities.maybeLog("Error in handleStartNewLevel", error)
    });
  }

  handleNewLevelLoaded() {
    this.handleMutatedGameState(true);
  }

  unhideProblematicInvoices() {
    let problematic_invoice_ids = this.state.invoices.filter(invoice => invoice.problem_exists).map(invoice => invoice.invoice_id);
    let new_hidden = this.state.hidden_invoice_ids.filter(hidden_invoice_id => problematic_invoice_ids.indexOf(hidden_invoice_id) == -1);
    this.setState({ hidden_invoice_ids: new_hidden });
  }

  handleRetryLevel() {
    let currentLevel = this.state.level ? this.state.level.ordinal : 0;
    fetch("/game/start_level", {
      method: 'post',
      body: JSON.stringify({
        level_ordinal: currentLevel,
        restart: "true",
      }),

      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Patio11Utilities.getCSRFToken(),
      }
    }).then((response) => {
      this.initializeNewLevel(response.json());
    }).then((res) => {
      Patio11Utilities.maybeLog("handleStartNewLevel response", res)
    }).catch((error) => {
      Patio11Utilities.maybeLog("Error in handleStartNewLevel", error)
    });
  }

  remoteCheckLevelCorrectness() {
    let currentLevel = this.state.level ? this.state.level.ordinal : 0;
    let invoicesAndTransactions = this.gameEngine.serializeInvoicesAndBankTransactions();

    fetch("/game/check_level", {
      method: 'post',
      body: JSON.stringify({
        level_ordinal: currentLevel,
        invoices_and_transactions: invoicesAndTransactions,
      }),

      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Patio11Utilities.getCSRFToken(),
      }
    }).then((response) => {
      this.checkLevelCorrectness(response.json());
    }).catch((error) => {
      Patio11Utilities.maybeLog("Error in remoteCheckLevelCorrectness", error)
    });
  }

  handleLevelDoneButton() {
    this.setState({
      enableLevelDoneButton: false,
    });
    this.remoteCheckLevelCorrectness();
  }

  handleNextLevelButton() {
    this.handleDismissModal();
  }

  handleDismissModal() {
    if (!this.state.gameTrulyOver) {
      if ("won" === this.state.won_or_lost) {
        this.handleStartNewLevel();
      } else if ("lost" === this.state.won_or_lost) {
        this.unhideProblematicInvoices();
        // actually don't
        // this.handleRetryLevel();
      }
    }
    this.setState({
      showResultModal: false,
      won_or_lost: null,
    });
  }

  checkLevelCorrectness = async (response) => {
    let response_value = await response;

    let levelWasWon = "won" === response_value.result;

    Patio11Utilities.maybeLog("CheckLevelCorrectness response", response_value);


    if (response_value.level && levelWasWon) {
      this.setState({
        level: {
          list_name: this.state.level.list_name,
          list_name_jp: this.state.level.list_name_jp,
          start_text: this.state.level.start_text,
          start_text_jp: this.state.level.start_text_jp,
          win_text: response_value.level.win_text,
          win_text_jp: response_value.level.win_text_jp,
          lose_text: response_value.level.lose_text,
          lose_text_jp: response_value.level.lose_text_jp,
          ordinal: response_value.level.ordinal,
        },

        won_or_lost: response_value.result,
        showResultModal: true,
      });
      this.gameEngine = window.gameEngine = new GameEngine();
    }

    if (!levelWasWon) {
      this.setState({
        won_or_lost: response_value.result,
        showResultModal: true,
        level: {
          list_name: this.state.level.list_name,
          list_name_jp: this.state.level.list_name_jp,
          start_text: this.state.level.start_text,
          start_text_jp: this.state.level.start_text_jp,
          win_text: response_value.level.win_text,
          win_text_jp: response_value.level.win_text_jp,
          lose_text: response_value.level.lose_text,
          lose_text_jp: response_value.level.lose_text_jp,
          ordinal: response_value.level.ordinal,
        },
      });

      if (response_value.invoices_with_problems) {
        _.each(response_value.invoices_with_problems, ((invoiceId) => {
          let invoice = this.gameEngine.lookupInvoice(invoiceId);
          invoice.setProblem();
        }).bind(this))
      }
    }
  }

  initializeNewLevel = async (response) => {
    let new_state = {
      bank_transactions: [],
      invoices: [],
      level: {
        start_text: "Sample start text.",
        win_text: "Sample won text.",
        lose_text: "Sample lose text.",
        ordinal: 0,
        list_name: "Sample level name.",
      }
    }

    let response_value = await response;

    new_state.level = response_value.level;
    if (new_state.level) {
      new_state.invoices = response_value.level.invoices || [];
      new_state.bank_transactions = response_value.level.bank_transactions || [];
      this.setState({
        level: {
          start_text: new_state.level.start_text,
          start_text_jp: new_state.level.start_text_jp,
          list_name: new_state.level.list_name,
          list_name_jp: new_state.level.list_name_jp,
          ordinal: new_state.level.ordinal,
        }
      });
    }
    this.gameEngine = window.gameEngine = new GameEngine();
    Patio11Utilities.maybeLog("Here is newstate", new_state);

    gameEngine.populateFromSpecifiedData(new_state.invoices, new_state.bank_transactions);
    gameEngine.rehash();

    eventBus.emit("newLevelLoaded");
  }

  hideInvoice = (invoice_id) => {
    this.setState({hidden_invoice_ids: [...this.state.hidden_invoice_ids, invoice_id]})
  }

  unhideInvoice = (invoice_id) => {
    const new_hidden = this.state.hidden_invoice_ids.filter(hidden_invoice_id => hidden_invoice_id != invoice_id);
    this.setState({ hidden_invoice_ids: new_hidden });
  }

  reorderInvoices(result, fromIndex, toIndex) {
    if (fromIndex != toIndex) {
      const invoices = this.gameEngine.reorderInvoices(fromIndex, toIndex);
      this.setState({
        invoices
      });
    }
  }

  reorderBankTransactions(fromIndex, toIndex) {
    if (fromIndex != toIndex) {
      const bank_transactions = this.gameEngine.reorderBankTransactions(fromIndex, toIndex);
      this.setState({
        bank_transactions
      });
    };
  }

  reconcileInvoice(invoiceDroppableId, bankTransactionIndex) {
    var invoiceId = invoiceDroppableId.replace("invoice-droppable-", "");
    let invoice = this.gameEngine.lookupInvoice(invoiceId);
    let bank_transaction = this.state.bank_transactions[bankTransactionIndex];
    if (invoice && bank_transaction) {
      this.gameEngine.reconcileInvoice(invoice, bank_transaction);
      this.setState({
        invoices: this.gameEngine.invoices,
        bank_transactions: this.gameEngine.bank_transactions,
      });
    }
  }

  // Move the index of the transaction from source to destination, defining destination array if it doesn't exist.
  updateTransactionLocationsAfterDrag(draggableIndex, sourceId, destinationId) {
    // easier than making the state update idempotent
    if (sourceId == destinationId) {
      return;
    }
    let indexAtSource = this.state.transaction_locations[sourceId].indexOf(draggableIndex);
    if (indexAtSource == -1) {
      Patio11Utilities.maybeLog("Drag error", draggableIndex, sourceId, destinationId);
      return;
    }


    this.setState(state => ({
      transaction_locations: {
        ...state.transaction_locations,
        [sourceId]: state.transaction_locations?.[sourceId].filter(index => index != draggableIndex) || [],
        [destinationId]: [draggableIndex, ...state.transaction_locations?.[destinationId] || []],
      }
    }), (function() {
      // In a callback because depends on up-to-date location information for transactions.
      let enableLevelDoneButton = this.levelMightBeCompleteable();
      this.setState({
        enableLevelDoneButton: enableLevelDoneButton,
      });
    }).bind(this));    
  }

  dispatchEventsForDragEnd(result) {
    if (!result.destination) {
      Patio11Utilities.maybeLog("Drag missed any droppable.");
      Patio11Utilities.maybeLog(result);
      return;
    }

    if (result.type == "INVOICE") {
      this.reorderInvoices(
        result,
        result.source.index,
        result.destination.index);
    }

    if (result.type == "PAYMENT") {
      Patio11Utilities.maybeLog("dragging payment. result:", result);
      let droppableId = result.destination.droppableId;
      let draggedOntoInvoice = (droppableId.indexOf("invoice-droppable-") == 0);
      let draggedTransferIndex = this.state.transaction_locations[result.source.droppableId][0]

      if (draggedOntoInvoice) {
        let droppableId = result.destination.droppableId;
        // create a non-displayed "invoices" location to make sure the total number of indices in the location object is constant
        this.updateTransactionLocationsAfterDrag(draggedTransferIndex, result.source.droppableId, "invoices");
        this.reconcileInvoice(droppableId, result.source.index);
      } else {
        this.updateTransactionLocationsAfterDrag(draggedTransferIndex, result.source.droppableId, result.destination.droppableId);
      }
    }
  }

  setLanguage(language) {
    this.setState({ language: language });

    let languagePostURL = '/game/set_preferred_language/' + language;
    let currentLevel = this.state.level ? this.state.level.ordinal : 1;

    fetch(languagePostURL, {
      method: 'post',
      body: JSON.stringify({
        level_ordinal: currentLevel,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Patio11Utilities.getCSRFToken(),
      }
    }).then((response) => {
      Patio11Utilities.maybeLog("Language switched successfully serverside to: " + language);
      this.initializeNewLevel(response.json());
      Patio11Utilities.maybeLog("Level re-initialized");
    }).then((res) => {

    }).catch((error) => {
      Patio11Utilities.maybeLog("Error in setLanguage POST", error)
    });
  }

  cycleStack(stackName) {
    let old_stack = this.state.transaction_locations[stackName];
    let new_stack = [...old_stack.slice(1), old_stack[0]];
    this.setState(state => ({
      transaction_locations: {
        ...state.transaction_locations,
        [stackName]: new_stack,
      }
    }
    ));
  }

  render() {
    let default_level_name = <span>How to play (<strong>temporary content</strong>) </span>
    let default_level_instructions = "Drag the transactions to the associated invoice."
    let level = this.state.level;
    let levelStartText = default_level_instructions;



    let resultText = "";
    let levelWasWon = false;
    let wonOrLostText = "";
    let renderEnglish = "en" === this.state.language;
    let modalButtonText = "";
    let characterPortrait = "neutral";

    if (level && level.start_text) {
      levelStartText = level.start_text; // Already localized by server.
    };

    let beatGame = false;

    levelWasWon = levelWasWon || this.state.justCheatingYourselfReally;

    if (level) {
      levelWasWon = levelWasWon || ("won" === this.state.won_or_lost);
      if (levelWasWon) {
        resultText = renderEnglish ? level.win_text : level.win_text_jp;
        wonOrLostText = renderEnglish ? "Well done!" : "作業完了！";
        modalButtonText = renderEnglish ? "Onwards!" : "進みましょう！";
        characterPortrait = "happy";
      } else {
        resultText = renderEnglish ? level.lose_text : level.lose_text_jp;
        wonOrLostText = renderEnglish ? "We screwed up!" : "待ってください！";
        modalButtonText = renderEnglish ? "I'll try again!" : "もう一回やらせてください！";
        characterPortrait = "horror";
      }

      if (levelWasWon && ((level.ordinal == 12) || (this.state.justCheatingYourselfReally))) {
        beatGame = true;
        characterPortrait = "ecstatic"; 
      }
    }

    let resultModal = (<div />);

    if (this.state.showResultModal) {
      let resultModalProps = {
        characterPortrait: characterPortrait,
        modalText: resultText,
        wonOrLost: wonOrLostText,
        buttonText: modalButtonText,
        onButtonClick: this.handleNextLevelButton,
        characterPortrait: characterPortrait,
        highlightErrors: !levelWasWon,
        beatGame: beatGame,
        language: this.state.language,
      };
      resultModal = (<ResultModal {...resultModalProps} />);
    }

    let splashScreen = <div />

    let megaButtonText = ""
    if (level && this.state.splash_screen) {
      if (renderEnglish) {
        if (level.ordinal == 1) {
          megaButtonText = "Start Game";
        } else {
          megaButtonText = "Continue";
        }
      } else {
        if (level.ordinal == 1) {
          megaButtonText = "スタート";
        } else {
          megaButtonText = "ゲームを続ける";
        }
      }
    }

    let footer = <footer className="flex-footer">
      <div className="links">
        <a href="/about">{renderEnglish ? "About" : "サイトについて"}</a>
        <a href="/game/cheat/clear_session">{renderEnglish ? "Restart Game" : "リスタート"}</a>
      </div>
      <div className="logo">
        &copy; <a href="https://www.kalzumeus.com">Kalzumeus Software, LLC</a> 2022
      </div>
    </footer>;

    // mega button accepts text and children now
    let levelDoneButtonText = "Reconciliation done!";
    if (!renderEnglish) {
      levelDoneButtonText = <>
        <span className="nowrap">消し込み作業</span>
        <span className="nowrap">完了！！</span>
      </>; // Do our best not to break lines weirdly
    }

    if (this.state.splash_screen) {
      splashScreen = <div className='splash-screen'>
        <LanguageSwitcher setLanguage={this.setLanguage} language={this.state.language} />
        <img
          src={this.state.language == "en" ? "/images/keshikomi-title-english.png" : "/images/keshikomi-title-japanese.png"}
          className="splash-screen-title"
          alt={this.state.language == "en" ? "Keshikomi Simulator" : "消し込みシミュレーター"}
        />
        <MegaButton onClick={() => this.setState({ splash_screen: false })} text={megaButtonText} centeredButton/>
        {footer}
      </div>
    }

    let bgImagePath = this.state.language == "en" ? "/images/background-no-blur-english.png" : "/images/background-no-blur-japanese.png";

    if (this.state.splash_screen) {
      return <>
        <img src={bgImagePath} className='bg-image' />
        {splashScreen}
      </>
    }

    let inboxLocalized = renderEnglish ? "Inbox" : "新規トレイ";
    let deskLocalized =  renderEnglish ? "Desk" : "作業中";
    let outboxLocalized = renderEnglish ? "Follow-ups" : "要確認";

    let hiddenInvoicesLabel = renderEnglish ? "Hidden Invoices" : "隠した請求書";

    return (<>
      {this.state.showResultModal ? resultModal : null}
      <img src={bgImagePath} className={'bg-image bg-image-blur'} />
      <DragDropContext onDragEnd={this.dispatchEventsForDragEnd}>
        <div className="game-container">
          <div className="text-box floater">
            <img className='text-box-portrait hide-if-narrow' src="/images/chatbox-senpai.png" />
            <div className="text-box-not-image">
              <div className="text-box-title">
                {level?.list_name ? level.list_name : default_level_name}
              </div>
              <div className="text-box-body">
                <ReactMarkdown children={levelStartText} />
              </div>
            </div>
          </div>
          <MegaButton
            expand
            onClick={this.handleLevelDoneButton}
            disabled={!this.state.enableLevelDoneButton}>{levelDoneButtonText}</MegaButton>
          <div className="bank-transfer-bar" >
            <div>
              <TransactionStack
                userFacingName={outboxLocalized}
                cycleCallback={() => this.cycleStack("droppableTransactionOutbox")}
                language={this.state.language}
                transaction={this.state.bank_transactions[this.state.transaction_locations['droppableTransactionOutbox']?.[0]]}
                index={this.state.transaction_locations['droppableTransactionOutbox']?.[0]}
                droppableId="droppableTransactionOutbox"
                canHoldMultiple={true}
                backupTransaction={this.state.bank_transactions[this.state.transaction_locations['droppableTransactionOutbox']?.[1]]}
                count={this.state.transaction_locations['droppableTransactionOutbox']?.length || 0}
                alternative="outbox"

              />
            </div>
            <div>
            <TransactionStack
              userFacingName={deskLocalized}
              cycleCallback={() => this.cycleStack("droppableTransactionDesk1")}
              language={this.state.language}
              transaction={this.state.bank_transactions[this.state.transaction_locations['droppableTransactionDesk1']?.[0]]}
              index={this.state.transaction_locations['droppableTransactionDesk1']?.[0]}
              droppableId="droppableTransactionDesk1"
              backupTransaction={this.state.bank_transactions[this.state.transaction_locations['droppableTransactionDesk1']?.[1]]}
              count={this.state.transaction_locations['droppableTransactionDesk1']?.length || 0}
              canHoldMultiple={true}
              hideIfNarrowAndEmpty
              alternative="verbose"
            /> </div>
            <div>
              <TransactionStack
                userFacingName={inboxLocalized}
                cycleCallback={() => this.cycleStack("droppableTransactionInbox")}
                language={this.state.language}
                transaction={this.state.bank_transactions[this.state.transaction_locations['droppableTransactionInbox']?.[0]]}
                index={this.state.transaction_locations['droppableTransactionInbox']?.[0]}
                droppableId="droppableTransactionInbox"
                canHoldMultiple={true}
                backupTransaction={this.state.bank_transactions[this.state.transaction_locations['droppableTransactionInbox']?.[1]]}
                count={this.state.transaction_locations['droppableTransactionInbox']?.length || 0}
                alternative="verbose"
              />
            </div>
          </div>
          <div className="hidden-invoice-list">
            <div>{hiddenInvoicesLabel}</div>
            {this.state.hidden_invoice_ids.map((invoice_id) => <span title={invoice_id } className="floating-icon-button" onClick={ () => this.unhideInvoice(invoice_id) } key={'hidden-invoice-' + invoice_id}>
              <FontAwesomeIcon icon='file-invoice'></FontAwesomeIcon>
            </span>)}
          </div>
          <InvoiceList
            invoices={this.state.invoices.filter(invoice => !this.state.hidden_invoice_ids.includes(invoice.invoice_id))}
            hiddenInvoiceIds={this.state.hidden_invoice_ids}
            hideInvoice={this.hideInvoice}
            handleDragEvent={this.handleDragEvent}
            language={this.state.language}
            className="inbox" />
          {
            /* built into formatting engine, behaves well present or absent */
            /* null && */
            footer
          }
        </div>
      </DragDropContext>
    </>
    )
  };
}

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    (<KeshikomiGame />),
    document.body.appendChild(document.createElement('div'))
  )
});