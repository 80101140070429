// See: https://fontawesome.com/v5/docs/web/use-with/react


import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faStripe } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faArrowsRotate, faCoffee, faFileInvoice, faYenSign, faBuildingColumns, faMoneyBillTransfer, faInbox, faEraser, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faInboxIn, faInboxOut, faArrowUpLeft } from '@fortawesome/pro-solid-svg-icons'

library.add(fab, faCheckSquare, faCoffee, faFileInvoice,
  faYenSign, faBuildingColumns, faStripe, faMoneyBillTransfer,
  faEraser, faInbox, faInboxIn, faInboxOut, faPaperclip, faArrowsRotate, faArrowUpLeft);


// To embed:
// Your <FontAwesomeIcon icon="coffee" /> is hot and ready!