import React from 'react';
import _ from 'underscore';

import { Droppable, Draggable } from "react-beautiful-dnd";

import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BankTransaction from './bank_transaction';

class InvoiceCard extends React.Component {

  constructor(props) {
    super(props);

    this.handlePaymentOnInvoice = this.handlePaymentOnInvoice.bind(this);
  }

  handlePaymentOnInvoice() { }


  render() {
    let invoice = this.props.invoice;
    let invoiceId = invoice.invoice_id;
    let customerName = invoice.customer_name;
    let amount = invoice.amount;
    let index = this.props.index;
    let reconciled = invoice.reconciled();
    let reconciledAmount = invoice.reconciledAmount();

    let invoiceOuterClassName = "invoice floater draggable" + (reconciled ? " reconciled" : "") + (this.props.invoice.associated_transactions.length == 0 ? " invoice-empty" : "");

    if (invoice.hasProblem()) {
      invoiceOuterClassName = invoiceOuterClassName + " problematic";
    }

    let reconciledAmountClass = "reconciled-amount"
    if (reconciledAmount == 0) {
      reconciledAmountClass = reconciledAmountClass + "--unreconciled";
    } else if (reconciledAmount > amount) {
      reconciledAmountClass = reconciledAmountClass + "--alarming";
    } else if (reconciledAmount < amount) {
      reconciledAmountClass = reconciledAmountClass + "--partial";
    } else {
      reconciledAmountClass = reconciledAmountClass + "--perfect";
    }

    invoiceOuterClassName = invoiceOuterClassName + " " + reconciledAmountClass;
    let invoiceHeaderName = ("en" === this.props.language) ? "Invoice" : "請求書";

    let associatedTransactionsClass = "associated-transactions";
    if (_.isEmpty(invoice.associated_transactions)) {
      associatedTransactionsClass = associatedTransactionsClass + " empty"
    }
    
    let has_transactions = this.props.invoice.associated_transactions.length > 0;
    let hide_invoice_button = <button className="floating-icon-button hide-invoice-position bigger-icon" onClick={this.props.hideInvoice}><FontAwesomeIcon icon='arrow-up-left'></FontAwesomeIcon></button>

    if (has_transactions) {
      return (<Draggable id={invoiceId} key={invoiceId} draggableId={invoiceId} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={invoiceOuterClassName}
          >
            {hide_invoice_button}
            <Droppable type="PAYMENT" id={"invoice-droppable-" + invoiceId} droppableId={"invoice-droppable-" + invoiceId}>
              {(provided, snapshot) => {
                let invoiceKey = "invoice-droppable-" + invoiceId;
                return (<div key={invoiceKey}>
                  <div ref={provided.innerRef}>
                    {
                      invoice.associated_transactions.map((t, idx) => {
                        let transactionKey = (t.transactionId ? t.transactionId : idx);
                        transactionKey = "embedded-transaction-" + invoiceKey + "-" + transactionKey;
                        return <BankTransaction key={transactionKey} transaction={t} index={idx} insideInvoice></BankTransaction>;
                      })
                    }
                    <InvoiceDetails invoice={this.props.invoice} amount={amount} detailed={false} invoiceHeaderName={invoiceHeaderName}/>
                  </div>
                  {provided.placeholder}
                </div>)
              }
              }
            </Droppable>
          </div>
        )}
      </Draggable>);
    } else {
      return (<Draggable id={invoiceId} key={invoiceId} draggableId={invoiceId} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={invoiceOuterClassName}
          >
            {hide_invoice_button}
            <Droppable type="PAYMENT" id={"invoice-droppable-" + invoiceId} droppableId={"invoice-droppable-" + invoiceId}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  <InvoiceDetails invoice={this.props.invoice} detailed={true} invoiceHeaderName={invoiceHeaderName} />
                  <div key={"invoice-droppable-" + invoiceId} ref={provided.innerRef} {...provided.droppableProps}>
                    <EmptyInvoiceDroppable amount={amount} />
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>);
    }
  }
};

class InvoiceDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className={this.props.detailed ? "invoice-header" : "invoice-footer"}>
      <div className='invoice-details'>
        {
          this.props.detailed ? (<>
            <table className='purple-boxes'>
              <tbody>
                <tr>
                  <td></td><td></td>
                </tr>
              </tbody>
            </table>
            <div className='invoice-title-row'>
              <span>{this.props.invoiceHeaderName}</span>
            </div>
          </>) : null
        }
        {
          this.props.detailed ?  <div className='invoice-id-row'>
            <span>{this.props.invoice.invoice_id}</span>
          </div> : null
        }
        <div className='invoice-name-row'>
          <span>{this.props.invoice.customer_name}</span>
        </div>
        {
          this.props.amount ? <div className='big-money-cell'>
            &yen; {this.props.amount.toLocaleString()}
          </div> : null
        }
      </div>
    </div>
  }
}

class EmptyInvoiceDroppable extends React.Component {
  constructor(props) {
    super(props);
  }
  //ref={this.props.droppableRef}

  render() {
    return <div  className="invoice-decorative-droppable">
      <table>
        <thead>
          <tr>
            <th className='width-1'></th><th className='width-2'></th><th className='width-1'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='width-1'></td><td className='width-2'></td><td className='width-1'></td>
          </tr>
          <tr>
            <td className='width-1'></td><td className='width-2'></td><td className='width-1'></td>
          </tr>
          <tr>
            <td className='dropped-cell'></td>
            <td colSpan='2' className='big-money-cell'>
              &yen; {this.props.amount.toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }
}

export default InvoiceCard;