import _ from 'underscore';

class Patio11Utilities {
  // swaps two elements from a list.
  static reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  static sumOf(list, fieldName) {
    let sum = 0;
    _.each(list, (e) => {
      sum = sum + e[fieldName]
    });
    return sum;
  }

  static getCSRFToken() {
    const csrfToken = document.querySelector("[name='csrf-token']")

    if (csrfToken) {
      return csrfToken.content
    } else {
      return null
    }
  }

  static maybeLog() {
    // `arguments` is apparently a reserved word for this exact use case!
    if (!window.location.hostname.match(/keshikomi/)) {
      console.log.apply(null, arguments);
    }
  }
}


export default Patio11Utilities;