import React from 'react';
import ReactDOM from 'react-dom';

class MegaButton extends React.Component {
  constructor(props) {
    super(props);
  }
  /*
    // SVG is giving me waaay more trouble than it's worth trying to get
    // it to match multiple button sizes.
    <svg className='mega-button-svg' viewBox="0 0 100% 100%" xmlns="http://www.w3.org/2000/svg">
      <path d="M152.5 39.68C33.5 39.68 -3.5 13.2267 -24 0L-18 124H326L331 0C309.833 13.2267 267.5 39.68 152.5 39.68Z" fill="#FFBB00" />
    </svg>
  */

  render() {
    let classes = ['floater'];
    if (this.props.mini) {
      classes.push('mega-button-wrapper-mini')
    } else {
      classes.push('mega-button-wrapper')
    }

    if (this.props.expand) {
      classes.push('expand')
    }

    if (this.props.centeredButton) {
      classes.push('centered-button');
    } 

    let buttonProps = {
      className: classes.join(' '),
    }

    if (this.props.disabled) {
      buttonProps["disabled"] = true;
    }

    return (
      <button {...buttonProps}>
        <div className={'mega-button' + (this.props.mini ? " mega-button-mini" : "")} onClick={this.props.onClick}>
          <span className='mega-button-text'>
            {this.props.children || this.props.text}
          </span>
        </div>
      </button>
    )
  }
}

export default MegaButton